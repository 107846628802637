.header{
    position: absolute;
    width: 100%;
    color: $white;
    padding-top:20px ;
    padding-bottom: 20px;

    &.privacy-page{
        position: static;
        color: $black;
    }

    .navigation{
        flex-direction: row;
        justify-content: space-between;
        padding: 0 16px;
       
        @include desktop {
        background: rgba(255, 255, 255, 0.08);
            backdrop-filter: blur(8px);
            border-radius: 8px;
        }
    }
    
    &-nav{
        display: flex;
        // gap: 16px;
        align-items: center;
        justify-content: space-between;
    }

    .list__item{
        padding: 0;
    }
    .burger-btn {
    background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(8px);
        padding: 12px;
        border-radius: 8px;
        margin: 0;
        border: none;
        color: inherit;
        cursor: pointer;
        transition: $transition;

        @include desktop {
            display: none;
        }

        & svg {
            stroke: currentColor;
        }

        &:hover,
        &:focus {
            color: $accent;
        }

        &:active {
            color: $second;
        }
    }
}


