.form{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 auto;
    width: 100%;
    @include tablet{
        max-width: 594px;
    }
    
    &-label{
        width: 100%;
    }

    &-input{
        width: 100%;
        padding: 15px 31px;
        background-color: $white;
        border: 2px solid $white;
        font-size: inherit;
        line-height: inherit;
        font-weight: 700;
        color: $black;
        transition: $transition;
        outline: none;

        &:hover,&:focus{
            border: 2px solid $accent;
        }

        &::placeholder{
            color: $black;
        }
    
    }



}