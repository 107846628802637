.hero{
    color: $white;
    padding-top: 100px;
    padding-bottom: 50px;

    @include tablet{
        padding-top: 0;
    }

    &.bg{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('../img/1.webp');
        min-height: 100vh;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

    }


    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        max-width: 810px;
        &.mobile-hidden{
            display: none;
            @include tablet {
                display: flex;
                text-align: left;
                }
        }
        &.tablet-hidden {
            

            @include tablet {
                display: none;

            }
        }


        
    }

    .heading1{
        text-transform: uppercase;
    }

    .btn{
        margin: 0 auto;
        @include tablet{
            margin: 0   ;
        }
    }   

}

.about{
    .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 50px;

        @include desktop{
            gap: 80px;
        }
    }

    .list__item{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 36px;

        width: 100%;
        max-width: 500px;

        @include desktop{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;  
            text-align: left;
            max-width: 100%;
            
            &:nth-child(2) {
                    flex-direction: row-reverse;
            }
        }

        .text-wrapper{
            max-width: 506px;

            .btn{
                margin: 0 auto;
                @include desktop{
                    margin: 0;
                }
            }
        }

        img{
            width: 100%;

            height: auto;
            @include desktop{
                max-width: 450px;
            }
        }


    }
}


.contacts{
    padding-top: 80px;
    padding-bottom: 80px;
    color: $white;
    text-align: center;
    &.bg{
        background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('../img/5.webp');
        
    }

    .content-wrapper{
        max-width: 712px;
        margin: 0 auto;
    }

}

