.footer{
    padding: 24px 0;


    .navigation{

        @include tablet{
            flex-direction: row;
            justify-content: space-between;
        }

    }

    .logo{
        &:hover,&:focus{
            color:$second;
        }
        
    }

    &-nav{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 5px;

        @include tablet{
            flex-direction: row;
        }

    }

    .link{
        font-weight: 400;
        text-align: center;
    }
}
    

