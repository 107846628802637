@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 64px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Roboto", sans-serif;
  background-color: #FFF;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #121212;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

section {
  padding: 25px 0;
}
@media screen and (min-width: 1200px) {
  section {
    padding: 80px 0;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
@media screen and (min-width: 1200px) {
  .navigation {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  font-family: inherit;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: #48C70D;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
}
.logo:hover, .logo:focus {
  color: #FFF;
}
.logo:active {
  color: inherit;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 10px 10px 9px 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: inherit;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
}
.link:hover, .link:focus {
  color: #48C70D;
  border-bottom: 1px solid #48C70D;
}
.link:active {
  color: #2c7a08;
  border-bottom: 1px solid transparent;
}

.btn {
  width: 100%;
  max-width: max-content;
  padding: 12px 24px;
  border-radius: 5px;
  border: none;
  background-color: #48C70D;
  color: #FFF;
  font-size: 16px;
  line-height: inherit;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
.btn-second {
  background-color: #FFF;
  border-radius: 0;
  color: #121212;
  text-transform: uppercase;
  margin-top: 8px;
  max-width: 100%;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0);
}
.hfa:hover, .hfa:focus {
  background-color: #2c7a08;
}
.hfa:active {
  background-color: #48C70D;
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}
.hfa-second:hover, .hfa-second:focus {
  color: #FFF;
  background-color: #48C70D;
}
.hfa-second:active {
  background-color: #FFF;
  color: #121212;
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}

.heading1 {
  font-size: 44px;
  font-weight: 800;
  line-height: 1.2;
}
@media screen and (min-width: 1200px) {
  .heading1 {
    font-size: 56px;
  }
}

.heading2 {
  font-size: 42px;
  font-weight: 800;
  line-height: 1.2;
}

.heading3 {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
}

.heading4 {
  font-style: 24px;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
  .border-b {
    border-bottom: 2px solid #48C70D;
  }
}

.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.just-validate-error-field.form-input {
  border: 2px solid #ff4242;
}

.just-validate-error-label {
  display: none;
}

.header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  br.tablet-hidden, .header-nav.tablet-hidden, .upper.tablet-hidden, img.tablet-hidden {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .header-nav.tablet-hidden, .upper.tablet-hidden {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  img.desktop-hidden, .btn-burger.desktop-hidden {
    display: none;
  }
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.submit {
  min-height: calc(100vh - 320px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .submit {
    min-height: calc(100vh - 210px);
  }
}
.submit .link {
  margin: 0 auto;
  margin-top: 100px;
}
.submit h1, .submit h2 {
  text-transform: uppercase;
}

.header {
  position: absolute;
  width: 100%;
  color: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header.privacy-page {
  position: static;
  color: #121212;
}
.header .navigation {
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
}
@media screen and (min-width: 1200px) {
  .header .navigation {
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
    border-radius: 8px;
  }
}
.header-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .list__item {
  padding: 0;
}
.header .burger-btn {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(8px);
  padding: 12px;
  border-radius: 8px;
  margin: 0;
  border: none;
  color: inherit;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@media screen and (min-width: 1200px) {
  .header .burger-btn {
    display: none;
  }
}
.header .burger-btn svg {
  stroke: currentColor;
}
.header .burger-btn:hover, .header .burger-btn:focus {
  color: #48C70D;
}
.header .burger-btn:active {
  color: #2c7a08;
}

.footer {
  padding: 24px 0;
}
@media screen and (min-width: 768px) {
  .footer .navigation {
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer .logo:hover, .footer .logo:focus {
  color: #2c7a08;
}
.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
@media screen and (min-width: 768px) {
  .footer-nav {
    flex-direction: row;
  }
}
.footer .link {
  font-weight: 400;
  text-align: center;
}

/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */
/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: #48C70D;
}
.hystmodal__close:focus {
  color: #48C70D;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: black;
  padding-top: 24px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.hystmodal.hystmodal--burger .hystmodal__window .container {
  height: 100%;
}
.hystmodal.hystmodal--burger .navigation.burger {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav {
  gap: 30px;
  width: 100%;
  height: 100%;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav .list-link__item:last-child {
  margin-top: auto;
}
.hystmodal.hystmodal--burger address {
  margin-top: 200px;
}
.hystmodal.hystmodal--burger .header-address {
  align-items: flex-start;
  flex-direction: column;
}
.hystmodal.hystmodal--burger a.header-address-link {
  color: #121212;
  max-width: 100%;
}

.hero {
  color: #FFF;
  padding-top: 100px;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .hero {
    padding-top: 0;
  }
}
.hero.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/1.webp");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.hero .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  max-width: 810px;
}
.hero .content-wrapper.mobile-hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .hero .content-wrapper.mobile-hidden {
    display: flex;
    text-align: left;
  }
}
@media screen and (min-width: 768px) {
  .hero .content-wrapper.tablet-hidden {
    display: none;
  }
}
.hero .heading1 {
  text-transform: uppercase;
}
.hero .btn {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .hero .btn {
    margin: 0;
  }
}

.about .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
@media screen and (min-width: 1200px) {
  .about .list {
    gap: 80px;
  }
}
.about .list__item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 36px;
  width: 100%;
  max-width: 500px;
}
@media screen and (min-width: 1200px) {
  .about .list__item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    max-width: 100%;
  }
  .about .list__item:nth-child(2) {
    flex-direction: row-reverse;
  }
}
.about .list__item .text-wrapper {
  max-width: 506px;
}
.about .list__item .text-wrapper .btn {
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .about .list__item .text-wrapper .btn {
    margin: 0;
  }
}
.about .list__item img {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 1200px) {
  .about .list__item img {
    max-width: 450px;
  }
}

.contacts {
  padding-top: 80px;
  padding-bottom: 80px;
  color: #FFF;
  text-align: center;
}
.contacts.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/5.webp");
}
.contacts .content-wrapper {
  max-width: 712px;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form {
    max-width: 594px;
  }
}
.form-label {
  width: 100%;
}
.form-input {
  width: 100%;
  padding: 15px 31px;
  background-color: #FFF;
  border: 2px solid #FFF;
  font-size: inherit;
  line-height: inherit;
  font-weight: 700;
  color: #121212;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: none;
}
.form-input:hover, .form-input:focus {
  border: 2px solid #48C70D;
}
.form-input::placeholder {
  color: #121212;
}